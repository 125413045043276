import React, { useState, useEffect } from 'react';
import './ImportContactDetail.scss';
import { Modal, ModalBody } from 'reactstrap';
import { FiX } from "react-icons/fi";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from '../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from '../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading';
import CustomSelectOptionWithLabel from '../../reuseableComponent/CustomSelectWithLabel/CustomSelectOptionWithLabel';
import ServiceOrganization from '../../../services/organization/ServiceOrganization'
import ImportContactServices from '../../../services/newServices/ImportContactServices'
import { checkID, doToast } from '../../../helper/HelperGeneral';
import HelperInput from '../../../helper/HelperInput';
import HelperDate from '../../../helper/HelperDate'
import iconFilter from '../../../assets/img/icon-filter.svg';
import importFailed from '../../../assets/img/import/import-failed.svg'
import importCreate from '../../../assets/img/import/import-create.svg'
import importUpdate from '../../../assets/img/import/import-update.svg'
import importPending from '../../../assets/img/import/import-pending.svg'
import SearchBox from '../../reuseableComponent/searchBox/SearchBox'
import Loading from '../../reuseableComponent/loading/Loading';
import Pagination from '../../reuseableComponent/pagination/Pagination';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import CustomSelectOption from '../../reuseableComponent/customSelectOption/CustomSelectOption';

let ImportContactDetail = (props) => {

  const listPageOption = [
    {
      label: '20',
      value: 20,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  let { match, history } = props;
  let [searchVal, setSearchVal] = useState("");
  let [orgID, setOrgID] = useState(false);
  let [isLoadingDetail, setIsLoadingDetail] = useState(true)
  let [isLoadingRowData, setIsLoadingRowData] = useState(true)
  let [dataDetail, setDataDetail] = useState({})
  let [rowData, setRowData] = useState({});
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })

  let [listPage, setListPage] = useState(20)
  let [listPageVal, setListPageVal] = useState({
    label: '20',
    value: 20,
  })
  let [page, setPage] = useState(1);
  let [showModalFilterContact, setShowModalFilterContact] = useState(false)
  let [valFilterStatus, setValFilterStatus] = useState({
    label: "",
    value: "",
    badgeClass: "",
    badgeText: "",
  })
  let [valFilterStatusOrigin, setValFilterStatusOrigin] = useState({
    label: "",
    value: "",
    badgeClass: "",
    badgeText: "",
  })
  let [freezeRow, setFreezeRow] = useState(false);

  const STATUS_LIST = [
    {
      label: "",
      value: "created",
      badgeClass: "green-background",
      badgeText: "Created",
    },
    {
      label: "",
      value: "updated",
      badgeClass: "orange-background",
      badgeText: "Updated",
    },
    {
      label: "",
      value: "pending",
      badgeClass: "yellow-background",
      badgeText: "Pending",
    },
    {
      label: "",
      value: "failed",
      badgeClass: "red-background",
      badgeText: "Failed",
    }
  ];

  let onChangeSearchRow = (e) => {
    setSearchVal(e)
  }

  let toggleModalFilterContact = () => {
    if (!isLoadingRowData) {
      setValFilterStatusOrigin(valFilterStatus);
      setShowModalFilterContact(!showModalFilterContact);
    }
  }

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getDetail(paramID, match.params.id);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    if (freezeRow) {
      let elTr = document.querySelectorAll(".tr-row");

      if (elTr.length > 0) {
        for (let i = 0; i < elTr.length; i++) {
          let elTd = document.querySelectorAll(`.td-row-number-${i}`);

          if (elTd.length > 0) {
            elTd[0].style.height = `${elTr[i].clientHeight}px`;
          }
        }
      }
    }
  }, [freezeRow])

  useEffect(() => {
    doMount();
  }, []);

  let getDetail = (orgID, userID) => {
    setIsLoadingDetail(true);

    let data = {
      id: parseInt(userID)
    }

    ImportContactServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };
      if (response.dataResult.status === 200) {
        setDataDetail(response.dataResult.data);
        getRowData();
      }
      else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingDetail(false);
    });
  };

  let getRowData = (_page = false, status = false, search = false, pageSize = false) => {
    setIsLoadingRowData(true);

    let data = {
      "id": parseInt(props.match.params.id),
      "search": (search || search === "") ? search : searchVal,
      "status": status !== false ? status : valFilterStatus.value,
      "pageNumber": _page ? _page : page,
      "pageSize": pageSize ? pageSize : 20
    };

    ImportContactServices.getRow(props.match.params.orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        setRowData(response.dataResult.data);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingRowData(false);
    });
  };

  let back = () => history.push({
    pathname: `/o/${orgID}/operational/import-contacts`,
  });
  
  let onSelectListPage = (val) => {
    getRowData(1, false, false, val.value);
    setListPageVal(val);
    setListPage(val.value);
    setPage(1);
  }

  let handleChangePage = (val) => {
    setPage(val);
    getRowData(val, false, false, listPage);
  }

  let onSelectStatus = (e) => {
    setValFilterStatusOrigin(e)
  }

  let modalSelectStatus = () => {
    return (
      <Modal className="modal-select-status-contacts" isOpen={showModalFilterContact} toggle={toggleModalFilterContact}>
        <ModalBody>
          <p className='title-filter'>
            <img alt="" src={iconFilter} className={`icon-filter`} />
            <b>Filters</b>
          </p>

          <label>
            <b>Status</b>
          </label>

          <CustomSelectOptionWithLabel
            optionListProps={STATUS_LIST}
            valueDropdownProps={valFilterStatusOrigin}
            placeholderProps={"Please select"}
            onClickDropDownListOptionProps={(e) => onSelectStatus(e)}
            specificClassNameProps="custom-select-channel"
            isDisabled={false}
            noLabel
          />

          <div className='filter-button-wrapper'>
            <b
              className='clear-selection-button'
              onClick={() => {
                onSelectStatus({
                  label: "",
                  value: "",
                  badgeClass: "",
                  badgeText: ""
                })
              }}
            >
              <FiX />
              Clear Selection
            </b>

            <ButtonWithLoadingOrIcon
              text="Cancel"
              className="no-fill-button main-button-40 button-submit-filter-status cancel-filter"
              onClickAction={() => {
                toggleModalFilterContact();
              }}
            />

            <ButtonWithLoadingOrIcon
              text="Apply Filters"
              className="orange-button main-button-40 button-submit-filter-status apply-filter"
              onClickAction={() => {
                setValFilterStatus(valFilterStatusOrigin);
                toggleModalFilterContact();
                setPage(1);
                getRowData(1, valFilterStatusOrigin.value, false, listPage);
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="sectionWrap integration-main-container detail-row-container text-left">
        {
          (isLoadingDetail || retriveDataError.code) ?
            <RetrieveDataLoading
              isLoading={isLoadingDetail}
              errorMessage={retriveDataError.message}
            />
            :
            <>
              <BackButton
                text={`Back to Import Contacts`}
                onClick={() => back()}
              />

              {modalSelectStatus()}

              <div className="detail-row-box">
                <div className="detail-row-box-top">
                  <div className="title-wrapper">
                    <b>Import Details</b>
                  </div>
                </div>

                <div className="detail-row-box-form">
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <b>File Name</b>
                    </div>
                    <p>{dataDetail.import.filename}</p>
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <b>Imported by</b>
                    </div>
                    <p>{dataDetail.import.createdByAccountName}</p>
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <b>Status</b>
                    </div>
                    <p className="margin-0">
                      {dataDetail.import.status === "pending" &&
                        <div className="yellow-background badge-text text-capitalize">
                          {dataDetail.import.status}
                        </div>
                      }

                      {dataDetail.import.status === "running" &&
                        <div className="blue-background badge-text text-capitalize">
                          {dataDetail.import.status}
                        </div>
                      }

                      {dataDetail.import.status === "finished" &&
                        <div className="green-background badge-text text-capitalize">
                          {dataDetail.import.status}
                        </div>
                      }

                      {dataDetail.import.status === "failed" &&
                        <div className="red-background badge-text text-capitalize">
                          {dataDetail.import.status}
                        </div>
                      }
                    </p>

                    {dataDetail.import.reason !== "" ?
                      <p className="red-text import-reason">
                        {dataDetail.import.reason}
                      </p>
                      :
                      ""
                    }
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <b>Submitted Time</b>
                    </div>
                    <p>{HelperDate.formatToString(new Date(dataDetail.import.createdTime), "dd MMMM yyyy, HH:mm")}</p>
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <b>Started Time</b>
                    </div>
                    <p>{dataDetail.import.startTime === 0 ? "" : HelperDate.formatToString(new Date(dataDetail.import.startTime), "dd MMMM yyyy, HH:mm")}</p>
                  </div>

                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <b>Finished Time</b>
                    </div>

                    <p className="margin-0">{dataDetail.import.endTime === 0 ? "" : HelperDate.formatToString(new Date(dataDetail.import.endTime), "dd MMMM yyyy, HH:mm")}</p>
                  </div>
                </div>
              </div>

              <div className="detail-row-box detail-row-box-bottom">
                <div className="detail-row-box-top">
                  <div className="title-wrapper">
                    <b>Rows{rowData.totalItems ? ` (${rowData.totalItems})` : ""}</b>
                  </div>
                </div>

                <div className='import-status-top-wrapper'>
                  <SearchBox
                    onSubmitSearch={() => {
                      setPage(1);
                      getRowData(1, false, searchVal, listPage)
                    }}
                    onChangeInputSearch={(e) => onChangeSearchRow(e)}
                    searchValue={searchVal}
                    placeholder="Search"
                    isWaitResponse={isLoadingRowData}
                    onClearSearch={() => {
                      setSearchVal("");
                      setPage(1);
                      getRowData(1, false, "", listPage);
                    }}
                  />

                  <>
                    <div className={`contact-history-filter`} onClick={toggleModalFilterContact}>
                      <img alt="" src={iconFilter} className={`icon-filter`} />
                      Filters
                    </div>
                  </>
                </div>

                {!isLoadingRowData &&
                  <div className='import-status-count-wrapper'>
                    <div className='import-status-count-wrapper-inner'>
                      <img src={importPending} alt="" />

                      <b>
                        Pending
                      </b>

                      <p>
                        {rowData.statusSummary.totalPending}
                      </p>
                    </div>

                    <div className='import-status-count-wrapper-inner'>
                      <img src={importCreate} alt="" />

                      <b>
                        Created
                      </b>

                      <p>
                        {rowData.statusSummary.totalCreated}
                      </p>
                    </div>

                    <div className='import-status-count-wrapper-inner'>
                      <img src={importUpdate} alt="" />

                      <b>
                        Updated
                      </b>

                      <p>
                        {rowData.statusSummary.totalUpdated}
                      </p>
                    </div>

                    <div className='import-status-count-wrapper-inner'>
                      <img src={importFailed} alt="" />

                      <b>
                        Failed
                      </b>

                      <p>
                        {rowData.statusSummary.totalFailed}
                      </p>
                    </div>
                  </div>
                }

                {isLoadingRowData ?
                  <Loading />
                  :
                  <>
                    <div className="detail-row-table-wrapper-outer">
                      {/* {rowData.rows.length > 0 &&
                        <table className='custom-table-content table-row-number no-hover'>
                          <thead>
                            <tr>
                              <td>No.</td>
                            </tr>
                          </thead>

                          <tbody>
                            {rowData.rows.map((value, index) => {
                              return (
                                <tr key={`row-list-${index}`}>
                                  <td>{value.rowNo}.</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      } */}

                      <div
                        className="detail-row-table-wrapper"
                        onScroll={(e) => {
                          let el = document.querySelectorAll(".detail-row-table-wrapper");

                          if (el.length > 0) {
                            if (el[0].scrollLeft > 35) {
                              setFreezeRow(true);
                            } else {
                              setFreezeRow(false);
                            }
                          }
                        }}
                      >
                        <table className="custom-table-content table-detail-row no-hover">
                          <thead>
                            <tr>
                              <td className={freezeRow ? "freeze-tdhead" : ""}>No.</td>
                              {/* <td>Contact Segment</td>
                              <td>Full Name</td>
                              <td>Alias</td>
                              <td>Phone Number</td>
                              <td>Company Name</td>
                              <td>Job Title</td> */}
                              {dataDetail.contactFields.map((v, i) => {
                                return (
                                  <td key={`field-contact-${i}`}>
                                    {v.fieldName}
                                  </td>
                                )
                              })}

                              {dataDetail.contactCustomFields.map((v, i) => {
                                return (
                                  <td key={`field-contact-${i}`}>
                                    {v.fieldName}
                                  </td>
                                )
                              })}
                              <td>Status</td>
                              <td>
                                Reason
                              </td>
                              <td>
                                <CustomSelectOption
                                  optionListProps={listPageOption}
                                  valueDropdownProps={listPageVal}
                                  onClickDropDownListOptionProps={onSelectListPage}
                                  isDisabled={isLoadingRowData}
                                />
                              </td>
                              {/* <td>Started Time</td>
                              <td>Finished Time</td> */}
                            </tr>
                          </thead>

                          {rowData.rows.length < 1 ?
                            <tbody>
                              <tr>
                                <td colSpan={dataDetail.contactCustomFields.length + dataDetail.contactFields.length + 3} className="no-data-column">
                                  <b className="no-result-found">
                                    No Data found.
                                  </b>
                                </td>
                              </tr>
                            </tbody>
                            :
                            <tbody>
                              {rowData.rows.map((value, index) => {
                                return (
                                  <tr key={`row-list-${index}`} className={`tr-row tr-row-number-${index}`}>
                                    <td className={freezeRow ? `freeze-tdbody td-row-number-${index}` : `td-row-number-${index}`}>{value.rowNo}.</td>

                                    {dataDetail.contactFields.map((_v, _i) => {
                                      return (
                                        <td className="white-space-nowrap" key={`td-field-${_i}`}>
                                          {!value.input[_v.fieldCode] ?
                                            ""
                                            :
                                            value.input[_v.fieldCode] === "" ? "" : (_v.fieldCode === "phone" ? `+${HelperInput.phoneBeautify(value.input[_v.fieldCode])}` : value.input[_v.fieldCode])
                                          }
                                        </td>
                                      )
                                    })}

                                    {dataDetail.contactCustomFields.map((_v, _i) => {
                                      return (
                                        <td className="white-space-nowrap" key={`td-field-2-${_i}`}>
                                          {!value.inputCustomFields[_v.fieldCode] ?
                                            ""
                                            :
                                            value.inputCustomFields[_v.fieldCode] === "" ? "" : value.inputCustomFields[_v.fieldCode]
                                          }
                                        </td>
                                      )
                                    })}

                                    <td>
                                      {value.status === "pending" &&
                                        <div className="yellow-background badge-text text-capitalize">
                                          {value.status}
                                        </div>
                                      }

                                      {value.status === "created" &&
                                        <div className="green-background badge-text text-capitalize">
                                          {value.status}
                                        </div>
                                      }

                                      {value.status === "updated" &&
                                        <div className="orange-background badge-text text-capitalize">
                                          {value.status}
                                        </div>
                                      }

                                      {value.status === "failed" &&
                                        <div className="red-background badge-text text-capitalize">
                                          {value.status}
                                        </div>
                                      }
                                    </td>
                                    <td className="white-space-nowrap">
                                      {value.remark === "" ? "" : value.remark}
                                    </td>
                                    <td></td>
                                    {/* <td>
                                      {value.createdTime === 0 ? "" : HelperDate.formatToString(new Date(value.createdTime), "dd MMM yyyy, HH:mm")}
                                    </td>
                                    <td>
                                      {value.updatedTime === 0 ? "" : HelperDate.formatToString(new Date(value.updatedTime), "dd MMM yyyy, HH:mm")}
                                    </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          }
                        </table>
                      </div>
                    </div>
                    
                    {rowData.totalItems > 0 &&
                      <Pagination
                        total={rowData.totalItems}
                        page={page}
                        listPerPage={listPage}
                        step={1}
                        onChangePage={handleChangePage}
                      />
                    }
                  </>
                }
              </div>
            </>
        }
      </div>
    </SectionWrap>
  );
};

export default ImportContactDetail;