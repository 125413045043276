import React from 'react';
import './ChatRoomMessageOut.scss';
import MessageDeleteWhite from "../../../../assets/img/icon-notallowed-white.svg";
import ReplyOrange from "../../../../assets/img/reply-orange.svg";
import { FiExternalLink } from "react-icons/fi";
import { FaPhoneAlt } from "react-icons/fa";
import { setReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { connect } from 'react-redux';

var ChatRoomMessageOut = (props) => {
    let {
        singleChatDataProps,
    } = props;

    let renderCarouselOut = () => {
        let printCarouselButton = (type, text) => {
            return (
                <div className="button-carousel">
                    {type === "call_to_action" &&
                        <FaPhoneAlt />
                    }

                    {type === "visit_website" &&
                        <FiExternalLink />
                    }

                    {type === "quick_reply" &&
                        <img src={ReplyOrange} alt="" />
                    }

                    {text}
                </div>
            )
        };

        return (
            <div className="message-out-bubble carousel-card">
                <div className="carousel-card-image-wrapper">
                    <div className="image-wrapper">
                        <img src={""} alt="" />

                        {/* <div className="icon-wrapper">
                            <FiImage />
                        </div> */}
                    </div>

                </div>

                <p>
                    Sleek, stylish, and functional, this Luxury Leather Wallet is crafted from premium materials. Perfect for gifting or upgrading your own everyday essentials!
                </p>

                <div className="button-carousel-wrapper">
                    {printCarouselButton("visit_website", "Visit")}
                    {printCarouselButton("call_to_action", "Call")}
                    {printCarouselButton("quick_reply", "Quick")}
                </div>
            </div>
        )
    }

    return (
        <div className={`chat-room-message-out-meta-carousel-wrapper`} id={`message-${singleChatDataProps.localID}`}>
            {singleChatDataProps.isDeleted ?
                <div className="message-out-bubble deleted-bubble">
                    <React.Fragment>
                        <img src={MessageDeleteWhite} alt="" className="deleted-icon" />
                        This message was deleted.
                    </React.Fragment>
                </div>
                :

                <div className={`message-out-bubble-carousel-wrapper-out`}>
                    {renderCarouselOut()}
                    {renderCarouselOut()}
                    {renderCarouselOut()}
                    {renderCarouselOut()}
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setReplyMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOut);
