import React from 'react';
import './PopupConfirmation.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ButtonWithLoadingOrIcon from '../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';

let PopupConfirmation = (props) => {
  let {
    isOpen,
    toggle,
    className,
    title,
    titleImage,
    description,
    children,
    isLoading,
    cancelText
  } = props;

  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     waitForResponseConfirmation: false,
  //   };
  // }

  // toggleModalPopupConfirmation = () => {
  //   this.props.onClickCancel();
  // };

  // onClickConfirmation = () => {
  //   this.props.onClickConfirmation();
  // };

  // componentWillReceiveProps(newProps) {
  //   const oldProps = this.props;
  //   if (oldProps.waitForResponse !== newProps.waitForResponse) {
  //     this.setState({
  //       waitForResponseConfirmation: newProps.waitForResponse,
  //     });
  //   }
  // }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={`modal-confirmation ${className}`}
      zIndex={9993}
    >
      <ModalBody>
        <b className="confirmation-title">{titleImage} {title}</b>
        <p className="confirmation-description" dangerouslySetInnerHTML={{ __html: description }} />
      </ModalBody>

      <ModalFooter>
        <ButtonWithLoadingOrIcon
          text="Cancel"
          className="no-fill-button cancel-button main-button-48"
          onClickAction={() => { if (!isLoading) { toggle() } }}
        />

        {children}
      </ModalFooter>
    </Modal>
  )
}

export default PopupConfirmation;
