import React, { useState, useEffect } from 'react';
import './DetailSegment.scss'
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap'
import BackButton from '../../reuseableComponent/BackButton/BackButton'
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp"
import { FiPlus, FiInfo, FiTrash2, FiDownload } from 'react-icons/fi'
import { IoMdCloseCircle } from 'react-icons/io'
import SearchBox from "../../reuseableComponent/searchBox/SearchBox";
import CustomSelectOption from '../../reuseableComponent/customSelectOption/CustomSelectOption'
import Pagination from '../../reuseableComponent/pagination/Pagination'
import PopupRemove from '../../reuseableComponent/popupRemove/PopupRemove'
import ServiceOrganization from '../../../services/organization/ServiceOrganization'
import { checkID, doToast } from '../../../helper/HelperGeneral';
import HelperInput from '../../../helper/HelperInput';
import HelperDate from '../../../helper/HelperDate';
import circleRemove from '../../../assets/img/user-segment/circle-remove.svg'
import UserSegmentServices from '../../../services/newServices/UserSegmentServices';
import RetrieveDataLoading from '../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading'
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import BlockingDiscardChanges from '../../reuseableComponent/blockingDiscardChanges/BlockingDiscardChanges'
import { NavLink } from 'react-router-dom';

let DetailSegment = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [query, setQuery] = useState("")
  let [totalUsers, setTotalUsers] = useState(0)
  let [pageDisplay, setPageDisplay] = useState(1)
  let [listPage, setListPage] = useState(20)
  let [isSelect, setIsSelect] = useState(false);
  let [countRemoveContact, setCountRemoveContact] = useState(0);
  let [dataToBeRemoved, setDataToBeRemoved] = useState([]);
  let [listPageVal, setListPageVal] = useState({
    label: '20',
    value: 20,
  })
  let [segmentName, setSegmentName] = useState("")
  let [segmentNameOrigin, setSegmentNameOrigin] = useState("")
  let [initialSegmentName, setInitialSegmentName] = useState("")
  let [isShowModalRemoveContact, setIsShowModalRemoveContact] = useState(false)
  let [isShowModalRemoveContactRow, setIsShowModalRemoveContactRow] = useState(false)
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  let [retriveDataErrorContact, setRetrieveDataErrorContact] = useState({
    code: false,
    message: false
  })
  let [dataUserSegment, setDataUserSegment] = useState({})
  let [isLoadingUserSegment, setIsLoadingUserSegment] = useState(false)
  let [isLoadingUserContactSegment, setIsLoadingUserContactSegment] = useState(false)
  let [submitQuery, setSubmitQuery] = useState("")
  let [dataUserContactSegment, setDataUserContactSegment] = useState([])
  let [isLoadingRemoveContact, setIsLoadingRemoveContact] = useState(false)
  let [dataRowToBeRemoved, setDataRowToBeRemoved] = useState([])
  let [errorMessageData, setErrorMessageData] = useState({
    name: ""
  })
  let [waitForResponseRemoveSegment, setWaitForResponseRemoveSegment] = useState(false)
  let [isShowModalRemoveSegment, setIsShowModalRemoveSegment] = useState(false)
  let [loadingDownloadSegment, setLoadingDownloadSegment] = useState(false)

  let toggleDownloadSegment = () => {
    setLoadingDownloadSegment(!loadingDownloadSegment);
  }

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getDetailSegment(paramID, match.params.id)
      getUserList(paramID, match.params.id)
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    doMount()
  }, [])

  let getDetailSegment = (orgID, segmentID) => {
    setIsLoadingUserSegment(true)

    UserSegmentServices.detailSegment(orgID, segmentID, response => {
      let _retriveDataError = { ...retriveDataError };
      if (response.dataResult.status === 200) {
        setDataUserSegment(response.dataResult.data.segment);
        setInitialSegmentName(response.dataResult.data.segment.name)
        setSegmentName(response.dataResult.data.segment.name)
        setSegmentNameOrigin(response.dataResult.data.segment.name)
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        // doToast(response.dataResult.error.message, 'fail');
      }
      setIsLoadingUserSegment(false);
    })
  }

  let getUserList = (orgID, segmentID, dataContact = {}) => {
    let _retriveDataErrorContact = { ...retriveDataErrorContact };
    setIsLoadingUserContactSegment(true)

    let data = {}
    if (Object.keys(dataContact).length === 0) {
      data.pageDisplay = pageDisplay
      data.listPage = listPage
      data.submitQuery = submitQuery
    } else {
      data.pageDisplay = dataContact.pageDisplay
      data.listPage = dataContact.listPage
      data.submitQuery = dataContact.submitQuery
    }

    UserSegmentServices.getUserList(orgID, segmentID, data.pageDisplay, data.listPage, data.submitQuery, (response) => {
      if (response.dataResult.status === 200) {
        setDataUserContactSegment(response.dataResult.data.users)
        setTotalUsers(response.dataResult.data.totalUsers)
      } else {
        _retriveDataErrorContact.message = response.dataResult.error.message;
        _retriveDataErrorContact.code = response.dataResult.error.code;
        setRetrieveDataErrorContact(_retriveDataErrorContact);
        // doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingUserContactSegment(false)
    })
  }

  let back = () => {
    if (!isLoadingChangeName && !waitForResponseRemoveSegment && !isLoadingRemoveContact) {
      history.push(`/o/${orgID}/operational/user-segment`)

    }
  }

  let onChangeSearch = (val) => {
    setQuery(val)
  }

  let onSubmitSearch = () => {
    let data = {
      pageDisplay: 1,
      listPage: listPage,
      submitQuery: query
    }
    let paramID = checkID(match);
    getUserList(paramID, match.params.id, data)
    setPageDisplay(1)
    setSubmitQuery(query)
  }

  let onClearSearch = () => {
    let data = {
      pageDisplay: 1,
      listPage: listPage,
      submitQuery: ""
    }
    let paramID = checkID(match);
    getUserList(paramID, match.params.id, data)
    setPageDisplay(1)
    setSubmitQuery("")
  }

  let onClickCheckAll = (e) => {
    let targetCheckBox = document.querySelectorAll('.custom-table-content tbody input[type="checkbox"]');

    targetCheckBox.forEach(item => {
      item.checked = e.target.checked ? true : false
    })

    let _dataToBeRemoved = [...dataUserContactSegment];

    if (e.target.checked) {
      let _dataToSelect = _dataToBeRemoved.filter((el) => {
        return !dataToBeRemoved.find(user => user.userID === el.userID)
      });
      let _dataSelect = [...dataToBeRemoved, ..._dataToSelect]

      setDataToBeRemoved(_dataSelect);
      setIsSelect(_dataSelect.length < 1 ? false : true);
      setCountRemoveContact(_dataSelect.length);
    } else {
      let _dataUnSelect = dataToBeRemoved.filter((el) => {
        return !_dataToBeRemoved.find(user => user.userID === el.userID)
      });
      setDataToBeRemoved([..._dataUnSelect]);
      setIsSelect(_dataUnSelect.length < 1 ? false : true);
      setCountRemoveContact(_dataUnSelect.length);
    }
  }

  let onClickSingleCheck = (e, item) => {
    let targetCheckBoxAll = document.querySelectorAll('#select-all');
    targetCheckBoxAll[0].checked = false;

    targetCheckBoxAll.checked = false;

    let _dataToBeRemoved = dataToBeRemoved;

    if (e.target.checked) {
      _dataToBeRemoved = [..._dataToBeRemoved, item]
    } else {
      let _dataRemove = [..._dataToBeRemoved]
      _dataToBeRemoved = _dataRemove.filter(el => el.userID !== item.userID)
    }

    setIsSelect(_dataToBeRemoved.length < 1 ? false : true);
    setCountRemoveContact(_dataToBeRemoved.length);
    setDataToBeRemoved(_dataToBeRemoved);
  }

  let isCheckedInputAll = () => {
    let isChecked = true;
    if (!isLoadingUserContactSegment) {
      if (dataUserContactSegment.length === 0) {
        isChecked = false;
        return;
      }

      let _data = [...dataToBeRemoved]
      // let _dataSegment = [...dataUserContactSegment]

      // _dataSegment.forEach(item => {
      //   if (_data.findIndex(val => val.userID === item.userID) === -1) {
      //     isChecked = false
      //   }
      // })
      if (_data.length === 0) {
        isChecked = false;
      }

      return isChecked
    }
  }

  let onChecked = (id) => {
    let _data = [...dataToBeRemoved]
    return _data.findIndex(val => val.userID === id) !== -1;
  }

  let listPageOption = [
    {
      label: '20',
      value: 20,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  let onSelectListPage = (val) => {
    let data = {
      pageDisplay: 1,
      listPage: val.value,
      submitQuery: submitQuery
    }
    let paramID = checkID(match);
    getUserList(paramID, match.params.id, data)
    setListPageVal(val)
    setListPage(val.value)
    setPageDisplay(1)
  }

  let onClickDelete = (e, item) => {
    let _dataRowToBeRemoved = [item]
    setDataRowToBeRemoved(_dataRowToBeRemoved)
    setIsShowModalRemoveContactRow(true)
  }

  // let validateSegmentName = () => {
  //   if (segmentName !== "" && segmentName !== initialSegmentName) {
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  let onChangeSegmentName = (e) => {
    let _errorMessageData = { ...errorMessageData };
    _errorMessageData.name = "";
    setErrorMessageData(_errorMessageData);
    setSegmentName(e.target.value)
  }

  let [isLoadingChangeName, setIsLoadingChangeName] = useState(false)

  let changeSegmentName = () => {
    let errorCount = 0;
    // let errorClass = "";
    let _errorMessageData = { ...errorMessageData };

    if (segmentName === "") {
      _errorMessageData.name = "This field is required";
      errorCount = errorCount + 1;
    }

    if (segmentName.length > 50) {
      _errorMessageData.name = "Characters exceed limit";
      errorCount = errorCount + 1;
    }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
    } else {
      setIsLoadingChangeName(true)
      // let paramID = checkID(match);

      UserSegmentServices.editSegment(orgID, match.params.id, segmentName, (response) => {
        setIsLoadingChangeName(false)

        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
          } else {
            // getDetailSegment(paramID, match.params.id)
            setSegmentNameOrigin(segmentName);
            doToast(response.dataResult.data.message);
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
        }
      })
    }
  }

  let handleChangePage = (page) => {
    setIsSelect(false);
    setDataToBeRemoved([]);
    setDataRowToBeRemoved([]);
    let data = {
      pageDisplay: page,
      listPage: listPage,
      submitQuery: submitQuery
    }
    let paramID = checkID(match);
    getUserList(paramID, match.params.id, data)
    setPageDisplay(page)
  }

  let onCancelRemove = () => {
    setIsSelect(false);
    setDataToBeRemoved([]);
    setCountRemoveContact(0);

    let allCheckBox = document.querySelectorAll('.custom-table-content input[type="checkbox"]');

    for (let i = 0; i < allCheckBox.length; i++) {
      allCheckBox[i].checked = false;
    }
  }

  let toggleModalRemoveContact = () => {
    setIsShowModalRemoveContact(!isShowModalRemoveContact)
  }

  let removeContact = () => {
    removeContactFromSegment(dataToBeRemoved)
  }

  let toggleModalRemoveContactRow = () => {
    setIsShowModalRemoveContactRow(!isShowModalRemoveContactRow)
  }

  let removeContactRow = () => {
    removeContactFromSegment(dataRowToBeRemoved)
  }

  let removeContactFromSegment = (data) => {
    setIsLoadingRemoveContact(true)

    let contactSelected = data.map(item => item.userID)
    let segmentID = match.params.id
    let paramID = checkID(match);

    UserSegmentServices.removeUsersFromSegment(orgID, segmentID, contactSelected, (response) => {
      if (response.dataResult.status === 200) {
        setIsLoadingRemoveContact(false)
        if (!response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
        } else {
          setIsShowModalRemoveContact(false)
          setIsShowModalRemoveContactRow(false)
          setIsSelect(false);
          setDataToBeRemoved([]);
          setCountRemoveContact(0);
          getUserList(paramID, match.params.id);
          doToast(response.dataResult.data.message);
        }
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  }

  let [confirmedNavigation, setConfirmedNavigation] = useState(false)
  let [condition, setCondition] = useState(false)
  let [isShowModalDiscard, setIsShowModalDiscard] = useState(false)

  let toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard)
  }

  let discardProgress = () => {
    setIsShowModalDiscard(false)
    setConfirmedNavigation(true)
  }

  let handleOpenModal = (isOpen) => {
    setIsShowModalDiscard(isOpen)
  }

  useEffect(() => {
    if (segmentName !== initialSegmentName) {
      setCondition(true)
    } else {
      setCondition(false)
    }
  }, [segmentName, initialSegmentName])

  let deleteSegment = () => {
    // let paramID = checkID(match);
    setWaitForResponseRemoveSegment(true)

    UserSegmentServices.deleteSegment(orgID, [parseInt(props.match.params.id)], (response) => {
      if (response.dataResult.error.message === "") {
        if (!response.dataResult.data.success) {
          doToast(response.dataResult.data.message, "fail");
          setWaitForResponseRemoveSegment(false);
        } else {
          doToast(response.dataResult.data.message);

          setTimeout(() => {
            back();
          }, 3000)
        }
      } else {
        setWaitForResponseRemoveSegment(false);
        doToast(response.dataResult.error.message, 'fail');
      }
    })
  }

  let toggleModalRemoveSegment = () => {
    setIsShowModalRemoveSegment(!isShowModalRemoveSegment)
  }

  let downloadReport = (data) => {
    // let id = orgID.split('-');
    // let timestamp = + new Date();
    let url = window.URL.createObjectURL(new Blob([data]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `contacts-data-${HelperDate.formatToString(new Date(), "dd-MM-yyyy")}.csv`);
    document.body.appendChild(link);
    link.click();
    setLoadingDownloadSegment(false);
  }

  let downloadContacts = () => {
    toggleDownloadSegment();
    let data = {
      id: parseInt(props.match.params.id),
      format: "csv"
    };

    UserSegmentServices.exportContacts(orgID, data,
      (response) => {
        if (response.dataResult.error) {
          doToast(response.dataResult.error.message, "fail");
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
      dataOrigin={segmentNameOrigin}
      dataAfterChange={segmentName}
    >
      {
        (isLoadingUserSegment || retriveDataError.code) ?
          <RetrieveDataLoading
            isLoading={isLoadingUserSegment}
            errorMessage={retriveDataError.message}
          />
          :
          // after load data
          <>
            <BlockingDiscardChanges
              openModal={handleOpenModal}
              condition={condition}
              confirmedNavigation={confirmedNavigation}
            />
            <PopupRemove
              removeTitle={`Discard Progress?`}
              removeDescription={`You have unsaved progress on this page. Are you sure you want to discard your progress?`}
              onClickCancel={toggleModalDiscard}
              onClickRemove={discardProgress}
              showModalProps={isShowModalDiscard}
              submitText="Discard Changes"
              colorButton="red-button"
            />

            <BackButton text="Back to Contact Segments" onClick={back} />

            <div className="sectionWrap detail-segment-main-wrapper">
              <div className="topContent detail-segment-header">
                <div className="title">
                  Segments Details

                  <ButtonWithLoadingOrIcon
                    className="red-button main-button-40"
                    text="Delete Segment"
                    onClickAction={toggleModalRemoveSegment}
                    icon={{
                      type: "svg",
                      src: FiTrash2
                    }}
                    position="left"
                  // isDisabled={validateSegmentName()}
                  />

                  {isLoadingChangeName ?
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingColor="gray"
                      className="orange-button main-button-40"
                      text="Save Changes"
                      position="left"
                      isDisabled
                    />
                    :
                    <ButtonWithLoadingOrIcon
                      className="orange-button main-button-40"
                      text="Save Changes"
                      onClickAction={changeSegmentName}
                    // isDisabled={validateSegmentName()}
                    />
                  }
                </div>
              </div>
              <div className="segment-name-wrapper">
                <b>
                  Segment Name
                  <span className={errorMessageData.name === "Characters exceed limit" ? "font-root-medium red-text absolute-right-0" : "font-root-medium grey-font absolute-right-0"}>{segmentName.length}/50</span>
                </b>
                <input disabled={isLoadingChangeName} type="text" className={`segment-name-input ${errorMessageData.name !== "" ? "border-red" : ""}`} placeholder="Segment name" value={segmentName} onChange={onChangeSegmentName} />

                {errorMessageData.name !== "" &&
                  <ErrorOrInfoComp
                    text={errorMessageData.name}
                    _className={"font-red"}
                    icon={<FiInfo />}
                  />
                }
              </div>
            </div>

            <div className="sectionWrap contact-detail-segment-main-wrapper">
              <>
                <div className="contact-detail-segment-header">
                  <div className="title"><b>Contacts</b></div>

                  {!retriveDataErrorContact.code &&

                    <>
                      {!isSelect ?
                        <>
                          {loadingDownloadSegment ?
                            <ButtonWithLoadingOrIcon
                              className="no-fill-button main-button-40 button-download-contact-segment"
                              text="Download Contacts"
                              position="left"
                              isLoading
                              loadingColor="gray"
                              isDisabled
                            />
                            :
                            <ButtonWithLoadingOrIcon
                              className="no-fill-button main-button-40 button-download-contact-segment"
                              text="Download Contacts"
                              position="left"
                              icon={{
                                type: "svg",
                                src: FiDownload
                              }}
                              onClickAction={downloadContacts}
                            />
                          }
                          <NavLink to={`/o/${orgID}/operational/user-segment/${dataUserSegment.id}/add-contact`}>
                            <ButtonWithLoadingOrIcon
                              className="no-fill-button main-button-40 button-add-into-segment"
                              text="Add Contacts Into Segment"
                              position="left"
                              icon={{
                                type: "svg",
                                src: FiPlus
                              }}
                            />
                          </NavLink>
                        </>
                        :
                        <div className="remove-segment-wrapper">
                          <ButtonWithLoadingOrIcon
                            className="no-fill-button main-button-40 cancel-delete-segment-btn"
                            text="Cancel"
                            onClickAction={() => onCancelRemove()}
                          />
                          <ButtonWithLoadingOrIcon
                            className="red-button main-button-40"
                            text={`Remove ${countRemoveContact} ${countRemoveContact > 1 ? 'Contacts' : 'Contact'}`}
                            onClickAction={() => setIsShowModalRemoveContact(true)}
                            icon={{
                              type: "img",
                              src: circleRemove
                            }}
                            position="left"
                          />
                        </div>
                      }
                    </>
                  }
                </div>

                {!retriveDataErrorContact.code &&
                  <SearchBox
                    onSubmitSearch={onSubmitSearch}
                    onChangeInputSearch={onChangeSearch}
                    searchValue={query}
                    placeholder="Search for a contact"
                    isWaitResponse={isLoadingUserContactSegment}
                    onClearSearch={onClearSearch}
                  />
                }
              </>

              {(isLoadingUserContactSegment || retriveDataErrorContact.code) ?
                <RetrieveDataLoading
                  isLoading={isLoadingUserContactSegment}
                  errorMessage={retriveDataErrorContact.message}
                />
                :
                // data on table
                <div className="contact-detail-segment-list-table-wrapper">
                  <div className="custom-table custom-table-show-overflow">
                    <table className="custom-table-content table-contact-detail-segment-list">
                      <thead>
                        <tr>
                          <td className="col_full_name">
                            <div className={dataToBeRemoved.length === dataUserContactSegment.length ? "custom-checkbox" : "custom-checkbox-stripe"}>
                              <input type="checkbox"
                                id="select-all"
                                onChange={(e) => onClickCheckAll(e)}
                                checked={isCheckedInputAll()}
                              />
                              <label htmlFor="select-all" className='label-select-all'></label>
                            </div>
                            Full Name
                          </td>
                          <td className="col_alias">Alias</td>
                          <td className="col_phone_number">Phone Number</td>
                          <td className="col_email">Email</td>
                          <td className="col_select_list">
                            <CustomSelectOption
                              optionListProps={listPageOption}
                              valueDropdownProps={listPageVal}
                              onClickDropDownListOptionProps={onSelectListPage}
                            />
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          dataUserContactSegment.length > 0 ?
                            dataUserContactSegment.map(item => {
                              return (
                                <tr
                                  key={`user-contact-segment-${item.userID}`}
                                >
                                  <td className="col_full_name">
                                    <div className="custom-checkbox">
                                      <input type="checkbox"
                                        id={`select-member-${item.userID}`}
                                        onChange={(e) => onClickSingleCheck(e, item)}
                                        checked={onChecked(item.userID)}
                                      />
                                      <label htmlFor={`select-member-${item.userID}`}></label>
                                    </div>
                                    <p>{item.fullName ? item.fullName : "-"}</p>
                                  </td>
                                  <td className="col_alias">
                                    {item.alias ? item.alias : "-"}
                                  </td>
                                  <td className="col_phone_number">
                                    {item.phone ? "+" + HelperInput.phoneBeautify(item.phone) : "-"}
                                  </td>
                                  <td className="col_email">
                                    {item.email ? item.email : "-"}
                                  </td>
                                  <td className="col_action">
                                    <button className="no-fill-button delete-btn" onClick={(e) => onClickDelete(e, item)}>
                                      <IoMdCloseCircle />
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              )
                            })
                            :
                            <tr>
                              <td colSpan="6" className="no-data-column"><b className="no-result-found">No Contacts found. Start by clicking "+Add Contacts Into Segment".</b></td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              }

              {
                dataUserContactSegment.length > 0 &&
                <div className="pagination-wrapper">
                  <Pagination
                    total={totalUsers}
                    page={pageDisplay}
                    listPerPage={listPage}
                    step={1}
                    onChangePage={handleChangePage}
                  ></Pagination>
                </div>
              }

              <PopupRemove
                removeTitle={`Remove ${countRemoveContact > 1 ? `${countRemoveContact} Contacts` : 'this Contact'}`}
                removeDescription={`Are you sure you want to remove ${countRemoveContact > 1 ? `${countRemoveContact} Contacts` : 'this Contact'}?`}
                onClickCancel={toggleModalRemoveContact}
                onClickRemove={removeContact}
                waitForResponse={isLoadingRemoveContact}
                showModalProps={isShowModalRemoveContact}
                submitText={`Remove ${countRemoveContact > 1 ? 'Contacts' : 'Contact'}`}
                colorButton="red-button"
              />
              <PopupRemove
                removeTitle={`Remove this Contact`}
                removeDescription={`Are you sure you want to remove this Contact?`}
                onClickCancel={toggleModalRemoveContactRow}
                onClickRemove={removeContactRow}
                waitForResponse={isLoadingRemoveContact}
                showModalProps={isShowModalRemoveContactRow}
                submitText={`Remove Contact`}
                colorButton="red-button"
              />
              <PopupRemove
                removeTitle={`Delete Segment?`}
                removeDescription={`Are you sure you want to delete this contact segment?`}
                onClickCancel={toggleModalRemoveSegment}
                onClickRemove={deleteSegment}
                waitForResponse={waitForResponseRemoveSegment}
                showModalProps={isShowModalRemoveSegment}
                submitText={`Remove Segment`}
                colorButton="red-button"
              />
              {/* </> */}
            </div>
          </>
      }

    </SectionWrap>
  );
};

export default DetailSegment;